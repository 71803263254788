import { createGlobalStyle } from 'styled-components'
export const GlobalStyles = createGlobalStyle`
:root {
  --primary: #1C659D;
  --secondary: #949494;
  --success: #5eba00;
  --info: #45aaf2;
  --warning: #f1c40f;
  --naranja: #f17c0f;
  --rosa: #fc58e0;
  --danger: #cd201f;
  --light: #e1e5eb;
  --dark: #302e2f;
  --white: #fff;
  --gray: #898989;
  --gray-light: #c3c5c5;
  --background: #EEECE9;
  --boxShadow: rgba(69, 90, 100, 0.08) 0px 1px 20px 0px;
  --boxShadowHover: rgba(69, 90, 100, 0.3) 0px 10px 20px 0px;
  --neumorphism: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
}

  body {
    transition: all 0.2s ease-in,;
    font-family: 'Montserrat', sans-serif;
    background: ${({ theme }) => theme.body};

  }

  .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
  
`

export const lightTheme = {
  body: '#F0F1F8',
  text: '#000',
  colorButtonCircle: '#404C77',
  boxShadow: '0px 2px 3px rgba(4, 4, 7, 0.1)',
  backgroundButton: '#FFF',
  border: '1px solid #eaedf1',
  colorSublink: '#6A788E',
  primary: '#6783A3',
  primary_scale1: 'rgba(82, 172, 224, 0.6)',
  primary_scale2: 'rgba(105, 224, 246, 0.2)',
  menu_category: 'rgb(138, 152, 172)',
  menu_link_active: '#FFF',
  hover_color: '#eaedf1',
  success: '#4EAA96',
  info: '#62A8EF',
  warning: '#EEBA45',
  danger: '#E4434D',
  white: '#fff',
  secondary: '#B7D6F9',
  purple: '#615BC6',
}
export const darkTheme = {
  body: '#1F1F34',
  text: '#fff',
  colorButtonCircle: '#fff',
  boxShadow: '0px 2px 3px #1a1a2f',
  backgroundButton: '#2A2B43',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  colorSublink: '#fff',
  primary: '#262F45',
  primary_scale1: 'rgba(82, 172, 224, 0.6)',
  primary_scale2: 'rgba(105, 224, 246, 0.2)',
  menu_category: 'rgb(138, 152, 172)',
  menu_link_active: '#FFF',
  hover_color: '#2e2e4a',
  success: '#4EAA96',
  info: '#62A8EF',
  warning: '#EEBA45',
  danger: '#E4434D',
  white: '#fff',
  secondary: '#B7D6F9',
  purple: '#615BC6',
}
