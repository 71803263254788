import React from 'react'
import styled from 'styled-components'
import { AiOutlineClose } from 'react-icons/ai'

const Moda = ({ children, visible, setVisible }) => {
  if (!visible) return null

  return (
    <ModalStyled>
      <Icon onClick={() => setVisible(false)} />
      <Container>{children}</Container>
    </ModalStyled>
  )
}

export default Moda

const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;
  width: 90vw;
  height: 90vh;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
  }
`

const Icon = styled(AiOutlineClose)`
  height: 30px;
  width: 30px;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 20px;
`
