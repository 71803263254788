import React from 'react'
import Inicio from './Inicio'
import Nosotros from './Nosotros'
import NuestrosServicios from './NuestrosServicios'
import Comunidad from './Comunidad'
import Escuela from './Escuela'
import Cuentanos from './Cuentanos'


const Home = () => {
  return (
    <>
      <Inicio />
      <Nosotros />
      <NuestrosServicios />
      <Comunidad />
      <Escuela />
      <Cuentanos />
    </>
  )
}

export default Home
