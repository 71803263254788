import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Title from '../../components/Title'
import Button from '../../components/Button'
import Aos from 'aos'
import { connect } from 'react-redux'
import { create } from '../../actions'
import { FaFacebook, FaInstagram, FaPhoneAlt } from 'react-icons/fa'
import 'aos/dist/aos.css'
import Swal from 'sweetalert2'

const Cuentanos = props => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  const [form, setForm] = useState({})

  const handleForm = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    props
      .create('contact', 'POST_BANNER', form)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Mensaje enviado',
          text: 'Pronto nos pondremos en contacto contigo',
          showConfirmButton: false,
          timer: 1500,
        })
      })
      .then(() => {
        document.getElementById('contactForm').reset()
      })
  }

  return (
    <Main>
      <Map
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d483.2033030057178!2d-98.21952780513196!3d18.976741034982414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfbf3499b366ed%3A0x2c4d4966b8b566fb!2sColegio%20Jean%20Piaget!5e0!3m2!1ses-419!2smx!4v1684355104253!5m2!1ses-419!2smx"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></Map>
      <Box>
        <Title data-aos="fade-left" data-aos-delay="100">
          Ubicación
        </Title>
        <Subtitle data-aos="fade-left" data-aos-delay="300">
          En el Sur de la Ciudad de México Av. San Francisco 419. Col. San
          Francisco, Del Magdalena Contreras.
        </Subtitle>
        <Grid>
          <div>
            <Title data-aos="fade-left" data-aos-delay="100">
              Encuentranos en:
            </Title>
            <Item>
              <FaFacebook /> Oli Jean Piaget - Educación Constructivista
            </Item>
            <Item>
              <FaInstagram /> @OliJeanPiaget
            </Item>
            <Item>
              <FaPhoneAlt /> 553-795-5005
            </Item>
          </div>
          <div>
            <Title data-aos="fade-left" data-aos-delay="100">
              Quiero informes
            </Title>
            <form id="contactForm" onSubmit={e => handleSubmit(e)}>
              <Label>
                Nombre
                <input name="name" type="text" onChange={e => handleForm(e)} />
              </Label>
              <Label>
                Email
                <input
                  name="email"
                  type="email"
                  onChange={e => handleForm(e)}
                />
              </Label>
              <Label>
                Teléfono
                <input name="phone" type="text" onChange={e => handleForm(e)} />
              </Label>
              <Label>
                Comentario
                <input
                  name="mesaje"
                  type="text"
                  onChange={e => handleForm(e)}
                />
              </Label>
              <ButtonDiv data-aos="fade-up" data-aos-delay="500">
                <Button type="submit">Enviar</Button>
              </ButtonDiv>
            </form>
          </div>
        </Grid>
      </Box>
    </Main>
  )
}

// Saludos a todos, me encantan tus videos, actualmente tengo 2 años de experiencia como full Stack, no se si especializarme en backend o en desarrollo movil en IOS. Saludos desde México

const mapDispatchToProps = {
  create,
}

export default connect(null, mapDispatchToProps)(Cuentanos)

const Main = styled.div`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Box = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  background-color: var(--white);
  @media (max-width: 768px) {
    grid-column: 1/2;
    padding: 20px;
    box-sizing: border-box;
    max-width: 100vw;
  }
`

const Subtitle = styled.p`
  color: var(--gray);
  font-size: 14px;
  line-height: 1.5;
  padding-bottom: 10px;
  @media (max-width: 768px) {
  }
`

const ButtonDiv = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Map = styled.iframe`
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    height: 300px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    & div {
      margin-bottom: 20px;
    }
  }
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-top: 10px;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  svg {
    height: 30px;
    width: 50px;
    margin-right: 10px;
  }
`
