import React, { useEffect } from 'react'
import styled from 'styled-components'
import img1 from '../../assets/static/about1.jpg'
import img2 from '../../assets/static/about2.jpg'
import img3 from '../../assets/static/about3.jpg'
import Aos from 'aos'
import Button from '../../components/Button'
import { Link } from 'react-router-dom'
import 'aos/dist/aos.css'
import logo from '../../assets/static/logo_svg_h.png'

const Nosotros = props => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <NosotrosContainer id="nosotros">
      <Container>
        <Logo src={logo} alt="oli" data-aos="fade-down" data-aos-delay="100" />
        {/*  <Title data-aos="fade-down" data-aos-delay="100">
          Comunidad Educativa
        </Title> */}
        <Paragraph data-aos="fade-right" data-aos-delay="300">
          Somos una institución consciente de las necesidades educativas que hoy en
          día se presentan, por lo que estamos comprometidos con la planeación y
          el seguimiento de la formación de buenos hábitos, el nivel de madurez
          y el desarrollo de habilidades de nuestros alumnos, logrando que estos
          aspectos formen una base sólida para su educación futura.
        </Paragraph>
        <Grid>
          <Card
            style={{
              backgroundImage: `url(${img1})`,
            }}
          >
            <Link to="/periodico-mural">
              <Button type="white">Periodico Mural</Button>
            </Link>
          </Card>
          <Card
            style={{
              backgroundImage: `url(${img3})`,
            }}
          >
            <Link to="/tareas">
              <Button type="white">Tareas</Button>
            </Link>
          </Card>
          <Card
            style={{
              backgroundImage: `url(${img2})`,
            }}
          >
            <Link to="/descargables">
              <Button type="white">Descargables</Button>
            </Link>
          </Card>
        </Grid>
      </Container>
    </NosotrosContainer>
  )
}

export default Nosotros

const NosotrosContainer = styled.div`
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1280px) 1fr;
  padding: 50px 0;
  background-color: #fff;
`
const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`
const Paragraph = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 100;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 100px;
  padding: 25px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`

const Card = styled.div`
  height: 300px;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

const Logo = styled.img`
  height: 100px;
`
