import React, { useState } from 'react'
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from 'swiper'
import styled from 'styled-components'
import Button from '../../components/Button'
import Paragraph from '../../components/Paragraph'
import Title from '../../components/Title'
import img1 from '../../assets/static/c1.jpg'
import img2 from '../../assets/static/c2.jpg'
import img3 from '../../assets/static/c3.jpg'
import img4 from '../../assets/static/c4.jpg'
import img5 from '../../assets/static/c5.jpg'
import Modal from '../../components/Modal'
import img37 from '../../assets/static/diapositivas/37.png'
import img38 from '../../assets/static/diapositivas/38.png'
import img39 from '../../assets/static/diapositivas/39.jpeg'
import img40 from '../../assets/static/diapositivas/40.jpeg'
import img41 from '../../assets/static/diapositivas/41.png'
import img42 from '../../assets/static/diapositivas/42.png'
import img43 from '../../assets/static/diapositivas/43.png'
import img44 from '../../assets/static/diapositivas/44.png'
import img50 from '../../assets/static/diapositivas/50.png'
import img51 from '../../assets/static/diapositivas/51.png'
import img52 from '../../assets/static/diapositivas/52.png'
// swiper core styles
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/scrollbar/scrollbar.min.css'

SwiperCore.use([EffectCoverflow, Pagination, Navigation])

const Portafolio = () => {
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [visible3, setVisible3] = useState(false)
  const [visible4, setVisible4] = useState(false)
  const [visible5, setVisible5] = useState(false)

  return (
    <Main>
      <Container2>
        <Title>Escuela Para Padres</Title>
        <Paragraph>
          Una clase mensual para aprender nuevas estrategias para educar
          conscientemente y apoyar a tus hijos en su crecimiento, complementando
          el trabajo que se realiza en la escuela como un equipo.
        </Paragraph>
      </Container2>
      <Container>
        <Card
          style={{
            backgroundImage: `url(${img1})`,
          }}
        >
          <Button type="white" onClick={() => setVisible5(true)}>
            Proximamente
          </Button>
        </Card>
        <Card
          style={{
            backgroundImage: `url(${img2})`,
          }}
        >
          <Button type="white" onClick={() => setVisible1(true)}>
            Integración de niños con necesidades especiales
          </Button>
        </Card>
        <Card
          onClick={() => setVisible2(true)}
          style={{
            backgroundImage: `url(${img3})`,
            backgroundPosition: 'center',
          }}
        ></Card>
        <Card
          onClick={() => setVisible3(true)}
          style={{
            backgroundImage: `url(${img4})`,
            backgroundPosition: 'center',
          }}
        >
          <Button type="white">Nuestras fortalezas</Button>
        </Card>
        <Card
          onClick={() => setVisible4(true)}
          style={{
            backgroundImage: `url(${img5})`,
            backgroundPosition: 'center',
          }}
        >
          <Button type="white">Desafios Siglo XXI</Button>
        </Card>
      </Container>
      <Modal visible={visible5} setVisible={setVisible5}>
        <Img src={img50} />
        <Img src={img51} />
      </Modal>
      <Modal visible={visible1} setVisible={setVisible1}>
        <Img src={img39} />
        <Img src={img40} />
      </Modal>
      <Modal visible={visible2} setVisible={setVisible2}>
        <Img3 src={img37} />
        <Img3 src={img38} />
        <Img3 src={img52} />
      </Modal>
      <Modal visible={visible3} setVisible={setVisible3}>
        <Img2 src={img42} />
        <Img2 src={img43} />
        <Img2 src={img44} />
      </Modal>
      <Modal visible={visible4} setVisible={setVisible4}>
        <Img src={img41} />
      </Modal>
    </Main>
  )
}

export default Portafolio

const Main = styled.div`
  background-color: #fff;
  width: 100vw;
  background-color: #fff;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1280px) 1fr;
  padding: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 50px 20px;
  }
`

const Container = styled.div`
  grid-column: 1 / 4;
  display: grid;
  padding: 0 20px;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Container2 = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 50px;
`

const Card = styled.div`
  height: 40vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-of-type(1) {
    height: 30vh;
  }
  &:nth-of-type(2) {
    height: 35vh;
  }
  &:nth-of-type(4) {
    height: 35vh;
  }
  &:nth-of-type(5) {
    height: 30vh;
  }

  @media (max-width: 768px) {
    height: 200px;
    &:nth-of-type(1) {
      height: 200px;
    }
    &:nth-of-type(2) {
      height: 200px;
    }
    &:nth-of-type(4) {
      height: 200px;
    }
    &:nth-of-type(5) {
      height: 200px;
    }
  }
`

const Img = styled.img`
  object-fit: contain;
  max-width: 50vh;
  margin: 20px;
  @media (max-width: 768px) {
    max-height: 40vh;
    max-width: 90vw;
  }
`
const Img2 = styled.img`
  object-fit: contain;
  max-width: 30vh;
  margin: 20px;

  @media (max-width: 768px) {
    max-width: 90vw;
  }
`
const Img3 = styled.img`
  object-fit: contain;
  max-height: 35vh;
  margin: 20px;

  @media (max-width: 768px) {
    max-width: 90vw;
  }
`
