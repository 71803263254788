export default function reducerCustomers(state = {}, action) {
  switch (action.type) {
    case 'GET_FILES':
      return {
        ...state,
        files: action.payload.data,
      }

    case 'GET_FILE':
      return {
        ...state,
        file: action.payload,
      }
    default:
      return state
  }
}
