import React, { useState } from 'react'
import Button from '../../components/Button'
import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import logo from '../../assets/static/logo.png'
import { AiOutlineMenu } from 'react-icons/ai'

const Header = () => {
  const [togleBar, setTogle] = useState(true)
  const handleTogle = () => {
    setTogle(!togleBar)
  }
  return (
    <HeaderS>
      <TopBar>
        <div>
          {/* <p>
            <a href="mailto: contacto@jeanpiaget.mx" target="blank">
              contacto@jeanpiaget.mx
            </a>{' '}
            | Aviso de Privacidad
          </p> */}
        </div>
      </TopBar>

      <Menu togleBar={togleBar}>
        <Img src={logo} alt="" />
        <ImgMenu onClick={handleTogle}>
          <AiOutlineMenu />
        </ImgMenu>
        <Ul togleBar={togleBar}>
          <Li>
            <HashLink smooth onClick={() => setTogle(false)} to="/#home">
              Inicio
            </HashLink>
          </Li>
          <Li>
            <HashLink smooth onClick={() => setTogle(false)} to="/#nosotros">
              nosotros
            </HashLink>
          </Li>
          <Li>
            <HashLink smooth onClick={() => setTogle(false)} to="/#servicios">
              modelo educativo
            </HashLink>
          </Li>
        {/*   <Li>
            <HashLink smooth onClick={() => setTogle(false)} to="/#portafolio">
              Becas
            </HashLink>
          </Li> */}
          <Li>
            <HashLink smooth onClick={() => setTogle(false)} to="/#contacto">
              contacto
            </HashLink>
          </Li>
          <Li>
            <Button>553 719 5005 / 551 675 7715</Button>
          </Li>
        </Ul>
      </Menu>
    </HeaderS>
  )
}

export default Header

const HeaderS = styled.header`
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1280px) 1fr;
  width: 100vw;
  max-width: 100vw;
  background-color: var(--white);
  box-sizing: border-box;
`
const TopBar = styled.div`
  background-color: var(--primary);
  height: 10px;
  grid-column: 1/4;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1280px) 1fr;
  & div {
    grid-column: 2/3;
    display: flex;
    justify-content: flex-end;
    color: var(--white);
    font-size: 10px;
    align-items: center;
    height: 100%;
    & p {
      margin: 0;
      & a {
        color: var(--white);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`

const Menu = styled.div`
  grid-column: 2/3;
  z-index: 11;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 0 25px;
  }
`
const Img = styled.img`
  height: 80px;
  object-fit: contain;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const ImgMenu = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  transition: all 1s ease-out;
  @media (max-width: 768px) {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s ease-out;
    background-color: #fff;
    transform: ${props =>
      props.togleBar === true ? 'translateX(-100%);' : 'translateX(0%);'};
  }
`
const Li = styled.li`
  text-align: center;
  display: inline-block;
  margin: 0;
  border-left: 1px solid var(--secondary);
  &:first-child {
    border-left: none;
  }
  &:last-child {
    border-left: none;
  }
  & a {
    margin: 15px 0;
    width: 100%;
    text-decoration: none;
    color: var(--secondary);
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 400;
    margin: 0 15px;
    @media (max-width: 768px) {
      color: var(--primary);
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`
