import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'

const PeriodicoMural = props => {
  const { banners } = props
  useEffect(() => {
    props.getAll('banners', 'GET_BANNERS')
    // eslint-disable-next-line
  }, [])

  console.log(banners)
  return (
    <Main>
      <Container>
        <Title size="big">Periodico Mural</Title>
        {banners && banners.length > 0 ? (
          banners.slice(0, 10).map((banner, index) => (
            <Item>
              <Title>{banner.title}</Title>
              <ItemContainer>
                <Img
                  key={index}
                  src={`${process.env.REACT_APP_API}public/banners/${banner.image}`}
                  alt=""
                />
                <p>{banner.text}</p>
              </ItemContainer>
            </Item>
          ))
        ) : (
          <p>No hay Noticias</p>
        )}
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    banners: state.banners.banners,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodicoMural)

const Main = styled.div`
  background-color: #fff;
  width: 100vw;
  background-color: #fff;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1280px) 1fr;
  padding: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 50px 20px;
  }
`

const Container = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const Img = styled.img`
  max-height: 30vh;
  margin-right: 20px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`

const ItemContainer = styled.div`
  display: flex;
  text-align: left;
  &:nth-child(odd) {
    flex-direction: row-reverse;
  }
`
