import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setTitle, getAll, create, update, deleted } from '../../actions'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import { AiOutlineEdit, AiOutlineDelete, AiOutlineFile } from 'react-icons/ai'
import Swal from 'sweetalert2'

const Users = props => {
  const { homeworks, setTitle, localization, themeApp } = props

  useEffect(() => {
    const topbar = {
      title: 'Tareas',
      buttonBar: (
        <Link to="/dashboard/homeworks/create">
          <Button>Crear</Button>
        </Link>
      ),
    }
    setTitle(topbar)
    props.getAll('homeworks', 'GET_HOMEWORKS')
    // eslint-disable-next-line
  }, [])

  const handleDelete = userId => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Este proceso no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrar',
    }).then(result => {
      if (result.isConfirmed) {
        props.deleted(`homeworks/${userId}`, 'DELETE_USER').then(() => {
          props.getAll('homeworks', 'GET_HOMEWORKS')
        })
        Swal.fire('Borrado!', 'Borrado con exito.', 'success')
      }
    })
  }

  return (
    <>
      <MaterialTable
        columns={[
          { title: 'Titulo', field: 'title' },
          { title: 'Texto', field: 'text' },
          {
            title: 'Archivo',
            field: 'image',
            render: rowData => {
              return rowData.image === null ? (
                <>No hay archivo</>
              ) : (
                <a
                  href={`${process.env.REACT_APP_API}public/homeworks/${rowData.image}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button color="warning">
                    <AiOutlineFile />
                  </Button>
                </a>
              )
            },
          },
          {
            title: 'Acciones',
            field: 'id',
            render: rowData => (
              <>
                <Link to={`/dashboard/homeworks/${rowData.id}`}>
                  <Button color="warning">
                    <AiOutlineEdit />
                  </Button>
                </Link>
                <Button color="danger" onClick={() => handleDelete(rowData.id)}>
                  <AiOutlineDelete />
                </Button>
              </>
            ),
          },
        ]}
        localization={localization}
        data={homeworks}
        title="Lista de Tareas"
        // editable={editable}
        options={{
          pageSize: 50,
          pageSizeOptions: [50, 100, 150],
          emptyRowsWhenPaging: false,
          filterCellStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          headerStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          rowStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          actionsCellStyle: {
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          searchFieldStyle: {
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          detailPanelColumnStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
        }}
        style={{
          backgroundColor: `${
            themeApp.theme === 'light'
              ? themeApp.lightTheme.backgroundButton
              : themeApp.darkTheme.backgroundButton
          }`,
          color: `${
            themeApp.theme === 'light'
              ? themeApp.lightTheme.colorButtonCircle
              : themeApp.darkTheme.colorButtonCircle
          }`,
        }}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    homeworks: state.homeworks.homeworks,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  update,
  deleted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
