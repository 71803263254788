import React from 'react'
// import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import styled from 'styled-components'
import img from '../../assets/static/ninios.jpg'
// import Button from '../../components/Button'
import logo from '../../assets/static/logo_svg_h.png'
import Paragraph from '../../components/Paragraph'
import Title from '../../components/Title'

// swiper core styles
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/scrollbar/scrollbar.min.css'

SwiperCore.use([Autoplay, Pagination, Navigation])

const Portafolio = () => {
  return (
    <Main>
      {/* <Swiper
        spaceBetween={20}
        slidesPerView={1}
        slidesPerColumn={1}
        navigation={false}
        loop={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: true,
        }}
      >
        <SwiperSlide>
          <Slide
            style={{
              backgroundImage: `linear-gradient(
                  rgba(0, 0, 0, .4),
                  rgba(0, 0, 0, .4)
                ), url(${img})`,
            }}
          >
            <Container>
              <Title>Colegio Jean Piaget</Title>
              <Subtitle>Lorem ipsum dolor sit amet consectetur.</Subtitle>
              <Text>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi,
                exercitationem qui optio fuga eligendi facere deleniti tempora,
                magni unde ratione ad in saepe autem voluptatibus harum quas cum
                doloremque sequi.
              </Text>
              <ButtonBar>
                <Button>Boton1</Button>
                <Button>Boton2</Button>
              </ButtonBar>
            </Container>
          </Slide>
        </SwiperSlide>
      </Swiper> */}
      <Container>
        <Logo src={logo} alt="oli" />
        <Title>
          Somos un Colegio de Educación Activa, Constructivista y Humanista.
        </Title>
        <Paragraph color={'primary'}>
          Dónde los propósitos, metas y proyectos de cada nivel se encaminan a
          la formación integral de nuestros alumnos a través de diversas
          actividades académicas, culturales, deportivas, artísticas y
          recreativas, que a lo largo de ciclo escolar se desarrollan;
          permaneciendo siempre en constante comunicación con los padres
          familia, considerando que son una parte fundamental en la educación de
          sus hijos.
        </Paragraph>
      </Container>
      <Container2>
        <Img src={img} alt="ninios" />
        <Title size={'big'}>“NUESTROS NIÑOS SON NUESTRO FUTURO”</Title>
      </Container2>
    </Main>
  )
}

export default Portafolio

const Main = styled.div`
  width: 100vw;
  background-color: #fff;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1280px) 1fr;
  padding: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 50px 20px;
  }
`

const Container = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }
`

const Container2 = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
`

const Logo = styled.img`
  height: 100px;
  object-fit: contain;
  @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
  }
`

const Img = styled.img`
  object-fit: contain;
  @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
  }
`

// const Title = styled.h1`
//   color: var(--white);
//   font-size: 3rem;
//   margin: 0;
// `

// const Slide = styled.div`
//   height: calc(100vh - 105px);
//   width: 100vw;
//   background-size: cover;
//   display: grid;
//   grid-template-columns: 1fr minmax(375px, 1280px) 1fr;
// `

// const Subtitle = styled.h2`
//   color: var(--white);
//   font-size: 1.3rem;
//   margin-top: 0;
// `

// const Text = styled.p`
//   font-size: 1.1rem;
//   color: var(--white);
//   text-align: left;
//   width: 50%;
// `

// const ButtonBar = styled.div``
