import React from 'react'
import styled from 'styled-components'

const Paragraph = ({ children, color = '', align = '' }) => {
  return (
    <ParagraphStyles color={color} align={align}>
      {children}
    </ParagraphStyles>
  )
}

export default Paragraph

const ParagraphStyles = styled.p`
  color: ${props => (props.color === '' ? '#000' : 'var(--primary)')};
  text-align: ${props => (props.align === '' ? 'justify' : props.align)};
`
