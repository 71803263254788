export default function reducerCustomers(state = {}, action) {
  switch (action.type) {
    case 'GET_HOMEWORKS':
      return {
        ...state,
        homeworks: action.payload.data,
      }

    case 'GET_HOMEWORK':
      return {
        ...state,
        homework: action.payload,
      }
    default:
      return state
  }
}
