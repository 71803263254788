import React, { useEffect } from 'react'
import styled from 'styled-components'
import img1 from '../../assets/static/Logo01.png'
import img2 from '../../assets/static/Logo02.png'
import img3 from '../../assets/static/Logo03.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

import kinder from '../../assets/static/pdf/kinder.pdf'
import primaria from '../../assets/static/pdf/primaria.pdf'
import maternalDay from '../../assets/static/pdf/maternal_day.pdf'

const Nosotros = props => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <NosotrosContainer id="nosotros">
      <Container>
        <Link href={maternalDay} target="_blank">
          <Img src={img3} data-aos="fade-down" data-aos-delay="100" />
        </Link>
        <Link href={kinder} target="_blank">
          <Img src={img2} data-aos="fade-down" data-aos-delay="100" />
        </Link>
        <Link href={primaria} target="_blank">
          <Img src={img1} data-aos="fade-down" data-aos-delay="100" />
        </Link>

        {/* <Title data-aos="fade-down" data-aos-delay="100">
          ¿Por qué Jean Piaget?
        </Title>
        <Paragraph data-aos="fade-right" data-aos-delay="300">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus,
          alias hic. Fuga, ipsam nemo temporibus iste impedit eaque a non
          recusandae corrupti voluptatem, architecto at repellendus quam?
          Libero, possimus consequuntur.
        </Paragraph>
        <Grid>
          <Card
            style={{
              backgroundImage: `url(${img1})`,
            }}
          >
            <Button type="white">Cooperación</Button>
          </Card>
          <Card
            style={{
              backgroundImage: `url(${img2})`,
            }}
          >
            <Button type="white">Cooperación</Button>
          </Card>
          <Card
            style={{
              backgroundImage: `url(${img3})`,
            }}
          >
            <Button type="white">Cooperación</Button>
          </Card>
          <Card
            style={{
              backgroundImage: `url(${img4})`,
            }}
          >
            <Button type="white">Cooperación</Button>
          </Card>
        </Grid>
        <Button>Modelo Educativo</Button> */}
      </Container>
    </NosotrosContainer>
  )
}

export default Nosotros

const NosotrosContainer = styled.div`
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1280px) 1fr;
  padding: 50px 0;
  background-color: #fff;
`
const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const Img = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 50px;
`

const Link = styled.a`
  width: 50%;
  margin-bottom: 50px;
  cursor: pointer;
`

/* const Paragraph = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 100;
  width: 50%;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 100px;
  padding: 25px 0;
`

const Card = styled.div`
  height: 300px;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`
 */
