import React from 'react'
import styled from 'styled-components'
// import img1 from '../../assets/static/face2.svg'
// import img2 from '../../assets/static/insta2.svg'
// import img3 from '../../assets/static/in2.svg'

import img4 from '../../assets/static/Logo01.png'
import img5 from '../../assets/static/Logo02.png'
import img6 from '../../assets/static/Logo03.png'

const Footer = () => {
  return (
    <Main>
      <Container2>
        <Img src={img4} alt="algo" />
        <Img src={img5} alt="algo" />
        <Img src={img6} alt="algo" />
      </Container2>
      <Container>
        <Title>Oli. All Right Reserved 2021.</Title>
        <div>
          <a href="https://www.facebook.com/backend.tech" target="blank">
            {/* <Icon src={img1} alt="" /> */}
          </a>
          <a href="https://www.instagram.com/backend_tech/" target="blank">
            {/* <Icon src={img2} alt="" /> */}
          </a>
          <a
            href="https://www.linkedin.com/showcase/backend_technologies"
            target="blank"
          >
            {/* <Icon src={img3} alt="" /> */}
          </a>
        </div>
      </Container>
    </Main>
  )
}

export default Footer

const Main = styled.footer`
  width: 100vw;
  background: var(--primary);
  display: grid;
  grid-template-columns: 1fr minmax(375px, 900px) 1fr;
  color: #fff;
  padding: 40px 0;
  max-width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
`

const Container = styled.div`
  grid-column: 2/3;
  text-align: center;
  display: flex;
  flex-direction: column;
`

const Container2 = styled(Container)`
  grid-column: 1/4;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Title = styled.h3`
  font-weight: 100;
  font-size: 12px;
`

const Img = styled.img`
  height: 150px;
  width: 150px;
  object-fit: contain;
`
