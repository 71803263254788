import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from '../layout'
import Login from '../pages/Login'
import Home from '../pages/Home'
import Users from '../pages/Users'
import UsersCreate from '../pages/Users/Create'
import UsersUpdate from '../pages/Users/Update'

import Banners from '../pages/Banners'
import BannerCreate from '../pages/Banners/create'
import BannerUpdate from '../pages/Banners/update'

import Contact from '../pages/Contact'

import EducacionActiva from '../pages/EducacionActiva'
import PeriodicoMural from '../pages/Banners/Web'

import Homework from '../pages/Homeworks'
import HomeworkWeb from '../pages/Homeworks/Web'
import HomeWorkCreate from '../pages/Homeworks/Create'
import HomeWorkUpdate from '../pages/Homeworks/Update'

import Files from '../pages/Files'
import FileCreate from '../pages/Files/create'
import FileUpdate from '../pages/Files/update'
import FilesWeb from '../pages/Files/Web'

const App = ({ loggedIn }) => {
  if (loggedIn) {
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/educacion-activa" element={<EducacionActiva />} />
            <Route path="/periodico-mural" element={<PeriodicoMural />} />
            <Route path="/tareas" element={<HomeworkWeb />} />
            <Route path="/descargables" element={<FilesWeb />} />

            <Route path="/dashboard" element={<Banners />} />
            <Route
              path="/dashboard/banners/create"
              element={<BannerCreate />}
            />
            <Route path="/dashboard/banners/:id" element={<BannerUpdate />} />

            <Route path="/dashboard/homeworks" element={<Homework />} />
            <Route path="/dashboard/homeworks/create" element={<HomeWorkCreate />} />
            <Route path="/dashboard/homeworks/:id" element={<HomeWorkUpdate />} />

            <Route path="/dashboard/files" element={<Files />} />
            <Route path="/dashboard/files/create" element={<FileCreate />} />
            <Route path="/dashboard/files/:id" element={<FileUpdate />} />


            <Route path="/dashboard/contact" element={<Contact />} />
            <Route path="/dashboard/users" element={<Users />} />
            <Route path="/dashboard/users/create" element={<UsersCreate />} />
            <Route path="/dashboard/users/:id" element={<UsersUpdate />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/educacion-activa" element={<EducacionActiva />} />
            <Route path="/periodico-mural" element={<PeriodicoMural />} />
            <Route path="/tareas" element={<HomeworkWeb />} />
            <Route path="/descargables" element={<FilesWeb />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  }
}

export default App

