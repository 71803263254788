import { combineReducers } from 'redux'
import reducerApp from './reducer'
import users from '../pages/Users/reducer'
import banners from '../pages/Banners/reducer'
import homeworks from '../pages/Homeworks/reducer'
import files from '../pages/Files/reducer'
import contact from '../pages/Contact/reducer'

const reducer = combineReducers({
  reducerApp,
  users,
  banners,
  homeworks,
  files,
  contact,
})

export default reducer
