import styled from 'styled-components'

const Title = ({ children, size = '' }) => (
  <TitleStyles size={size}>{children}</TitleStyles>
)

export default Title

const TitleStyles = styled.h2`
  color: var(--primary);
  margin: 0;
  font-size: ${props => {
    switch (props.size) {
      case 'sm':
        return '1rem'
      case 'mid':
        return '2rem'
      case 'big':
        return '2.5rem'
      default:
        return '1.5rem'
    }
  }};
`
