import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createFile, getAll, setTitle } from '../../actions'
import Card from '../../components/Card'
import Input from '../../components/Input'
import Button from '../../components/Button'
import styled from 'styled-components'

const CreateSupplier = props => {
  const [form, setForm] = useState({})
  const { setTitle } = props

  useEffect(() => {
    const topbar = {
      title: 'Crear descargable',
    }
    setTitle(topbar)

    // eslint-disable-next-line
  }, [])

  const handleForm = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleFile = e => {
    setForm({
      ...form,
      image: e.target.files,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    props.createFile('files', 'POST_BANNER', form).then(() => {
      document.getElementById('formUser').reset()
    })
  }

  return (
    <Card title={'Información del banner'}>
      <form id="formUser" onSubmit={e => handleSubmit(e)}>
        <Input
          type="text"
          name="title"
          title="Titulo"
          onChange={e => handleForm(e)}
          required
        />
        <Input
          type="file"
          name="image"
          title="Imagen"
          onChange={e => handleFile(e)}
          required
        />

        <BarButton>
          <Button type="submit" color="success">
            Crear
          </Button>
          <Link to="/dashboard/files">
            <Button className="btn --danger">Cancelar</Button>
          </Link>
        </BarButton>
      </form>
    </Card>
  )
}

const mapStateToProps = state => {
  return {
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  createFile,
  getAll,
  setTitle,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSupplier)

const BarButton = styled.div`
  margin-top: 1rem;
`
