import styles from 'styled-components'

const Button = ({ type, children, onClick, className, disabled }) => (
  <ButtonStyles
    className={`button ${className}`}
    onClick={onClick}
    disabled={disabled}
    type={type}
  >
    {children}
  </ButtonStyles>
)

export default Button

const ButtonStyles = styles.button`
  background: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 3px;
  color: var(--white);
  cursor: pointer;
  font-size: 1rem;
  margin: 1em;
  padding: 0.25em 1em;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;

  ${props =>
    props.type === 'white'
      ? `
  background: var(--white);
  color: var(--dark);
  border: 1px solid var(--white);
  padding: 1em 1em;
  border-radius: 0;
  `
      : null}

  &:hover {
    background: #eee;
    border-color: #ccc;
    color: #000;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #eee;
    border-color: #ccc;
    color: #ccc;
    cursor: not-allowed;
  }
`
