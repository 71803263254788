import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import img from '../../assets/static/diapositivas/49.png'
import img2 from '../../assets/static/diapositivas/9.png'
import img3 from '../../assets/static/diapositivas/7.png'
import img4 from '../../assets/static/diapositivas/10.png'
import img5 from '../../assets/static/diapositivas/8.png'
import { FaSchool, FaRegLightbulb } from 'react-icons/fa'
import { BiWorld } from 'react-icons/bi'
import { FiBookOpen } from 'react-icons/fi'
import { GiPerspectiveDiceSix } from 'react-icons/gi'
import Modal from '../../components/Modal'
import Paragraph from '../../components/Paragraph'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom'

const NuestrosServicios = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [visible3, setVisible3] = useState(false)
  const [visible4, setVisible4] = useState(false)

  return (
    <>
      <NewServicios>
        <Container>
          <Paragraph>
            Aplicamos el modelo educativo Activo High – Scope y nos basamos en
            los últimos avances de neuro educación para diseñar las actividades
            diarias, los espacios de trabajo y el ambiente socioemocional seguro
            y sano de apoyo que nos caracteriza.
          </Paragraph>
          <Paragraph align="center">
            "El Aprendizaje Significativo se obtiene a través de las
            Experiencias"
          </Paragraph>
        </Container>
      </NewServicios>
      <NuestrosServiciosContainer id="servicios">
        <Container>
          <Grid>
            <Item onClick={() => setVisible1(true)}>
              <FaSchool />
              Nosotros
            </Item>
            <Item onClick={() => setVisible2(true)}>
              <FaRegLightbulb />
              Misión
            </Item>
            <Item onClick={() => setVisible3(true)}>
              <BiWorld />
              Visión
            </Item>
            <Item onClick={() => setVisible4(true)}>
              <FiBookOpen />
              Valores
            </Item>
            <StyledLink to="/educacion-activa">
              <GiPerspectiveDiceSix />
              Objetivos
            </StyledLink>
          </Grid>

          <Paragraph>
            Hoy en día, nuestro Sistema Educativo Humanista, es el Método
            Educativo de vanguardia usado en los países más avanzados,
            conscientes de la repercusión de una educación que desarrolla el
            potencial de las capacidades individuales de cada niño. Respetando
            esta individualidad, como habilidades únicas y diferentes con las
            que cuenta cada ser humano.
          </Paragraph>
        </Container>
        <Modal visible={visible1} setVisible={setVisible1}>
          <Img src={img} alt="Nosotros" />
        </Modal>
        <Modal visible={visible2} setVisible={setVisible2}>
          <Img src={img2} alt="Misión" />
        </Modal>
        <Modal visible={visible3} setVisible={setVisible3}>
          <Img src={img3} alt="Nosotros" />
          <Img src={img5} alt="Nosotros" />
        </Modal>
        <Modal visible={visible4} setVisible={setVisible4}>
          <Img src={img4} alt="Nosotros" />
        </Modal>
      </NuestrosServiciosContainer>
    </>
  )
}

export default NuestrosServicios

const NuestrosServiciosContainer = styled.div`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 900px) 1fr;
  background-color: #fff;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 25px;
    box-sizing: border-box;
  }
`

const NewServicios = styled(NuestrosServiciosContainer)`
  background-color: #efece9;
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;

  padding: 25px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 25px;
  }
`

const Grid = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  color: var(--white);
  padding: 5px 25px;
  border-radius: 10px;
  width: 80px;
  cursor: pointer;
  background-color: #f6651e;
  text-decoration: none;
  & svg {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
    width: 100%;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  color: var(--white);
  padding: 5px 25px;
  border-radius: 10px;
  width: 80px;
  cursor: pointer;
  & svg {
    font-size: 40px;
  }
  &:nth-of-type(2) {
    background-color: #f7447a;
  }
  &:nth-of-type(3) {
    background-color: #fbae18;
  }
  &:nth-of-type(1) {
    background-color: #f6651e;
  }
  &:nth-of-type(4) {
    background-color: #2a9a63;
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
    width: 100%;
  }
`

const Img = styled.img`
  max-width: 50%;
  object-fit: contain;
  padding: 25px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`
