import React from 'react'
import styled from 'styled-components'
import Title from '../../components/Title'
import img19 from '../../assets/static/diapositivas/19.png'
// import img20 from '../../assets/static/diapositivas/20.png'
import img21 from '../../assets/static/diapositivas/21.png'
import img22 from '../../assets/static/diapositivas/22.png'
import img23 from '../../assets/static/diapositivas/23.png'
import img24 from '../../assets/static/diapositivas/24.png'
import img25 from '../../assets/static/diapositivas/25.png'
import img26 from '../../assets/static/diapositivas/26.png'
import img27 from '../../assets/static/diapositivas/27.png'
import img28 from '../../assets/static/diapositivas/28.jpeg'
import img29 from '../../assets/static/diapositivas/29.png'
import img30 from '../../assets/static/diapositivas/30.png'
import img31 from '../../assets/static/diapositivas/31.png'
import img32 from '../../assets/static/diapositivas/32.png'
import img33 from '../../assets/static/diapositivas/33.png'
import img34 from '../../assets/static/diapositivas/34.png'

const EducacionActiva = () => {
  return (
    <Main>
      <Container>
        <Title size="big">Educación Activa</Title>
        <Img src={img19} alt="" />
        <Img src={img21} alt="" />
        <Img src={img22} alt="" />
        <Img src={img23} alt="" />
        <Img src={img24} alt="" />
        <Img src={img25} alt="" />
        <Img src={img26} alt="" />
        <Img src={img27} alt="" />
        <Img src={img28} alt="" />
        <Img src={img29} alt="" />
        <Img src={img30} alt="" />
        <Img src={img31} alt="" />
        <Img src={img32} alt="" />
        <Img src={img33} alt="" />
        <Img src={img34} alt="" />
      </Container>
    </Main>
  )
}

export default EducacionActiva

const Main = styled.div`
  background-color: #fff;
  width: 100vw;
  background-color: #fff;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1280px) 1fr;
  padding: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 50px 20px;
  }
`

const Container = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 20px;
`
