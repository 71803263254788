import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import img from '../../assets/static/about3.jpeg'
import Button from '../../components/Button'

const PeriodicoMural = props => {
  const { homeworks } = props
  useEffect(() => {
    props.getAll('homeworks', 'GET_HOMEWORKS')
    // eslint-disable-next-line
  }, [])

  console.log(homeworks)
  return (
    <Main>
      <Container>
        <Title size="big">Tareas</Title>
        <Grid>
          {homeworks && homeworks.length > 0 ? (
            homeworks.slice(0, 10).map((banner, index) => (
              <Item>
                <Title>{banner.title}</Title>
                <ItemContainer>
                  <Img key={index} src={img} alt="" />
                <p>
                  {banner.created.split('T')[0]}
                </p>
                  <p>{banner.text}</p>
                  {
                    banner.image && <a
                    href={`${process.env.REACT_APP_API}public/homeworks/${banner.image}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button>Descargar Tarea</Button>
                  </a>
                  }
                </ItemContainer>
              </Item>
            ))
          ) : (
            <p>No hay Noticias</p>
          )}
        </Grid>
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    homeworks: state.homeworks.homeworks,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodicoMural)

const Main = styled.div`
  background-color: #fff;
  width: 100vw;
  background-color: #fff;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1280px) 1fr;
  padding: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 50px 20px;
  }
`

const Container = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
`

const Img = styled.img`
  max-height: 30vh;
  margin-right: 20px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  &:nth-child(odd) {
    flex-direction: row-reverse;
  }
`
